import * as React from 'react';
import { EXPERT_FORM_LINK } from 'utils/constants/pages';

const StaticNav: React.FC<{ getStartedId: string }> = ({ getStartedId }) => {
  return (
    <>
      <div className="fixed z-50 w-screen h-16 bg-white bg-opacity-95">
        <div className="container flex items-center justify-center h-full max-w-6xl px-8 mx-auto sm:justify-between xl:px-0">
          <a
            href="/"
            className="relative flex items-center inline-block h-5 h-full font-black leading-none"
          >
            <img src="/images/logo-name.png" style={{ height: '24px', width: 'auto' }} />
          </a>

          <nav className="absolute top-0 left-0 z-50 flex-col items-center justify-between hidden w-full h-64 pt-5 mt-24 text-sm text-gray-800 bg-white border-t border-gray-200 shadow-xl md:shadow-none md:flex md:w-auto md:flex-row md:h-24 lg:text-base md:bg-transparent md:mt-0 md:border-none md:py-0 md:relative">
            <a
              href="#"
              className="ml-0 mr-0 font-bold duration-100 md:ml-12 md:mr-3 lg:mr-8 transition-color hover:text-blue-600"
            >
              Home
            </a>
            <a
              href="#how-mavyn-works"
              className="mr-0 font-bold duration-100 md:mr-3 lg:mr-8 transition-color hover:text-blue-600"
            >
              How Mavyn Works
            </a>
            <a
              href={EXPERT_FORM_LINK}
              className="font-bold duration-100 transition-color hover:text-blue-600"
              target="_blank"
            >
              Become a Mavyn Expert
            </a>
            <div className="flex flex-col w-full font-medium border-t border-gray-200 md:hidden">
              <a
                href={`#${getStartedId}`}
                className="relative inline-block w-full px-5 py-3 text-sm leading-none text-center text-white bg-blue-600 fold-bold"
              >
                Get Started
              </a>
            </div>
          </nav>
          <div className="absolute left-0 flex-col items-center justify-center hidden w-full pb-8 mt-48 border-b border-gray-200 md:relative md:w-auto md:bg-transparent md:border-none md:mt-0 md:flex-row md:p-0 md:items-end md:flex md:justify-between">
            <a
              href={`#${getStartedId}`}
              className="relative z-40 inline-block w-auto h-full px-5 py-3 text-sm font-bold leading-none text-white transition-all transition duration-100 duration-300 bg-blue-600 rounded shadow-md fold-bold sm:w-full lg:shadow-none hover:shadow-xl"
              style={{ color: 'white' }}
            >
              Get Started
            </a>
          </div>

          <div className="absolute top-0 right-0 z-50 block w-6 mt-8 mr-10 cursor-pointer select-none md:hidden sm:mt-10">
            <span className="block w-full h-1 mt-2 duration-200 transform bg-gray-800 rounded-full sm:mt-1"></span>
            <span className="block w-full h-1 mt-1 duration-200 transform bg-gray-800 rounded-full"></span>
          </div>
        </div>
      </div>
      <div className="h-16 w-screen">&nbsp;</div>
    </>
  );
};

export default StaticNav;

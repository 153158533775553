import * as React from 'react';
import * as Sentry from '@sentry/nextjs';
import Notification from 'components/blocks/Notification';
import api from 'services/client/api';

const InlineEmail = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState<null | Error>(null);
  const [isShowSuccess, setIsShowSuccess] = React.useState(false);
  const [email, setEmail] = React.useState('');

  return (
    <>
      {isShowSuccess && <Notification defaultShowState={isShowSuccess} />}
      <div>
        {!!requestError && (
          <div className="mb-3 text-red-500">We had an issue. Try again in a few minutes...</div>
        )}
        <form
          className="flex w-full"
          onSubmit={async (e) => {
            e.preventDefault();

            if (isLoading) {
              return;
            }

            setIsLoading(true);
            setRequestError(null);
            try {
              await api.post('email-list', { payload: { email } });
              setEmail('');
              setIsShowSuccess(true);
              setIsLoading(false);
            } catch (error) {
              Sentry.captureException(error);
              setRequestError(error);
              setIsLoading(false);
            }
          }}
        >
          <div className="w-full">
            <label htmlFor="inline-email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="inline-email"
              id="inline-email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow-sm focus:ring-blue-600 focus:border-blue-600 block w-full sm:text-sm border-gray-300 rounded"
              placeholder="you@example.com"
              required
            />
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-2 flex-shrink-0 disabled:bg-blue-400"
          >
            {isLoading ? 'Submitting...' : 'Join Waitlist'}
          </button>
        </form>
      </div>
    </>
  );
};

export default InlineEmail;

import * as React from 'react';
import Head from 'next/head';
import { EXPERT_FORM_LINK } from 'utils/constants/pages';
import StaticNav from 'components/blocks/StaticNav';
// import FullForm from 'components/blocks/WaitList/FullForm';
import InlineEmail from 'components/blocks/WaitList/InlineEmail';
import useAppendScript from 'components/hooks/useAppendScript';
import { COMPANY_FACEBOOK_PAGE, COMPANY_INSTAGRAM } from 'utils/constants/internal';

import { VideoContainer } from './styles';

const FORM_NODE_ID = 'form-node';

// const faqs = [
//   {
//     id: 1,
//     question: "What's the ......?",
//     answer:
//       'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
//   },
// ];
const navigation = {
  main: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Accessibility', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: COMPANY_FACEBOOK_PAGE,
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: COMPANY_INSTAGRAM,
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: 'Twitter',
    //   href: '#',
    //   icon: (props: any) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    //     </svg>
    //   ),
    // },
  ],
};

// const people = [
//   {
//     name: 'Whitney Francis',
//     role: 'Copywriter',
//     imageUrl:
//       'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//     twitterUrl: '#',
//     linkedinUrl: '#',
//   },
//   {
//     name: 'Whitney Francis',
//     role: 'Copywriter',
//     imageUrl:
//       'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//     twitterUrl: '#',
//     linkedinUrl: '#',
//   },
//   {
//     name: 'Whitney Francis',
//     role: 'Copywriter',
//     imageUrl:
//       'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//     twitterUrl: '#',
//     linkedinUrl: '#',
//   },
//   {
//     name: 'Whitney Francis',
//     role: 'Copywriter',
//     imageUrl:
//       'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//     twitterUrl: '#',
//     linkedinUrl: '#',
//   },
//   {
//     name: 'Whitney Francis',
//     role: 'Copywriter',
//     imageUrl:
//       'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//     twitterUrl: '#',
//     linkedinUrl: '#',
//   },
//   {
//     name: 'Whitney Francis',
//     role: 'Copywriter',
//     imageUrl:
//       'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
//     twitterUrl: '#',
//     linkedinUrl: '#',
//   },
// ];

const HomePage: React.FC = () => {
  useAppendScript({
    src: '',
    nodeId: FORM_NODE_ID,
    code: `hbspt.forms.create({
      region: 'na1',
      portalId: '20825023',
      formId: '076a608a-a3c5-4e02-8c15-78b240c7ec1a',
    });`,
  });

  React.useEffect(() => {
    window.location.href = 'https://www.mavyn.com';
  }, []);

  return (
    <>
      <Head>
        <title>Mavyn</title>
        <link rel="canonical" href="https://www.mavyn.com" />
      </Head>
      <div className="bg-white">
        <StaticNav getStartedId={FORM_NODE_ID} />
        <div className="pt-8 sm:pt-12 lg:relative lg:py-24">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div>
                <div className="sm:max-w-xl">
                  <div>
                    <div className="inline-flex space-x-4">
                      <span className="rounded bg-blue-50 px-3 py-2 mb-3 text-sm font-semibold text-blue-600 tracking-wide uppercase">
                        Mavyn - Experts at Your Fingertips
                      </span>
                    </div>
                  </div>
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Always Keep An Expert On Hand
                  </h1>
                  <p className="mt-6 text-xl text-gray-500">
                    What's a socket wrench? Does this wallpaper match? How do you feed bees? Can I
                    train to run faster? Seriously, we've got you. Just pick up your smartphone,
                    tablet or computer and tap Mavyn. We'll have a trusted expert queued up to video
                    chat with you in minutes. Then it's a problem solved, issue managed, and check
                    off your to-do list.
                  </p>
                </div>
                {/* <div className="flex mt-12">
                  <a href="#" className="mr-6 w-48">
                    <img src="/images/app-store.png" />
                  </a>
                  <a href="#" className="mr-6 w-48">
                    <img src="/images/google-play.png" />
                  </a>
                </div> */}
              </div>
            </div>
            <div>
              <div className="sm:max-w-xl pt-8 md:pt-12 mx-auto">
                <VideoContainer className="max-w-md px-4 sm:max-w-3xl z-10">
                  <iframe
                    width="100%"
                    height="auto"
                    src="https://www.youtube.com/embed/sFCkyyY6_MU"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </VideoContainer>
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div className="hidden md:block">
              <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div className="hidden sm:block">
                  <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                  <svg
                    className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                    width={404}
                    height={392}
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={392}
                      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-3xl mt-8 px-4">
          <h3 className="text-lg text-blue-700 font-semibold">Join the Waitlist</h3>
          <div className="mb-2 text-gray-500 max-w-xl">
            Mavyn is wrapping up development right now. While the pixels settle, sign up for the
            beta release and be the first to get Mavyn in your hand.
          </div>
          {/* <FullForm /> */}
          <div className="min-h-4xl min-w-xs" id={FORM_NODE_ID} />
        </div>
        <section className="mt-12 px-4">
          <div className="text-lg max-w-prose mx-auto">
            <h2>
              <span className="block text-base text-center text-blue-700 font-semibold tracking-wide uppercase">
                Introducing
              </span>
              <h2 className="mt-2 text-3xl font-extrabold tracking-normal sm:text-6xl text-center mx-auto block w-full">
                Mavyn
              </h2>
            </h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Improving your life should be more accessible. For every small and big fork in the
              road, there could be a sign that said, ""this way is better"". That's Mavyn, a simple
              guide that helps you outmaneuver challenges and roadblocks with timely professional
              advice.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Delivered via video chat with nine areas of focus (and growing). Mavyn is there when
              and where you need input.
            </p>
          </div>
        </section>
        <section className="py-28 bg-white">
          <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
            <div className="relative">
              <h2
                className="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl"
                id="how-mavyn-works"
              >
                Complete your projects in 3 easy steps
              </h2>
              <p className="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">
                Mavyn is fast and super easy to use. You can get advise from experts on demand.
                Simply describe your project, and our coordinators will connect you with an expert.
              </p>
            </div>
            <div className="flex flex-col mb-8 animated fadeIn sm:flex-row max-w-3xl mx-auto">
              <div className="flex items-center mb-8 sm:w-1/2 md:w-4/12 sm:order-last">
                <img
                  src="/images/phone1.png"
                  alt=""
                  style={{ width: '256px', height: 'auto', maxHeight: '100%' }}
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-8/12 sm:pr-20">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-blue-600 uppercase">
                  Step 1
                </p>
                <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">Tap</h3>
                <p className="mt-5 text-xl text-gray-700 text md:text-left">
                  What can Mavyn help you with? Select your project type from a list or request an
                  expert.
                </p>
              </div>
            </div>
            <div className="flex flex-col mb-8 animated fadeIn sm:flex-row max-w-3xl mx-auto">
              <div className="flex items-center mb-8 sm:w-1/2 md:w-4/12 sm:order-last">
                <img
                  src="/images/phone2.png"
                  alt=""
                  style={{ width: '256px', height: 'auto', maxHeight: '100%' }}
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-8/12 sm:pr-20">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-blue-600 uppercase">
                  Step 2
                </p>
                <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">Share</h3>
                <p className="mt-5 text-xl text-gray-700 text md:text-left">
                  What's holding you back? Share a description of your issue and upload photos to
                  help our experts visualize the situation.
                </p>
              </div>
            </div>
            <div className="flex flex-col mb-8 animated fadeIn sm:flex-row max-w-3xl mx-auto">
              <div className="flex items-center mb-8 sm:w-1/2 md:w-4/12 sm:order-last">
                <img
                  src="/images/phone3.jpeg"
                  alt=""
                  style={{ width: '240px', height: 'auto', maxHeight: '100%' }}
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-8/12 sm:pr-20">
                <p className="mb-2 text-sm font-semibold leading-none text-left text-blue-600 uppercase">
                  Step 3
                </p>
                <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">Chat</h3>
                <p className="mt-5 text-xl text-gray-700 text md:text-left">
                  Let's talk this out. Connect live via video chat for expert advice and guidance
                  through your issue.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="max-w-7xl mx-auto mb-32 px-4 text-center sm:px-6 lg:px-8">
            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl mb-12">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-6xl">Meet our team</h2>
              <p className="text-xl text-gray-500">Built with ❤️ in Austin, TX</p>
            </div>
            <ul
              role="list"
              className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl"
            >
              {people.map((person, i) => (
                <li key={i}>
                  <div className="space-y-6">
                    <img
                      className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                      src={person.imageUrl}
                      alt=""
                    />
                    <div className="space-y-2">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>{person.name}</h3>
                        <p className="text-blue-600">{person.role}</p>
                      </div>
                      <ul role="list" className="flex justify-center space-x-5">
                        <li>
                          <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Twitter</span>
                            <svg
                              className="w-5 h-5"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href={person.linkedinUrl}
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">LinkedIn</span>
                            <svg
                              className="w-5 h-5"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section> */}
        {/* <section className="flex items-center justify-center py-20 bg-white min-w-screen">
          <div className="px-16 bg-white">
            <div className="container flex flex-col items-start mx-auto lg:items-center">
              <p className="relative flex items-start justify-start w-full text-lg font-bold tracking-wider text-blue-500 uppercase lg:justify-center lg:items-center">
                Don't just take our word for it
              </p>

              <h2 className="relative flex items-start justify-start w-full max-w-3xl text-5xl font-bold lg:justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="absolute right-0 hidden w-12 h-12 -mt-2 -mr-16 text-gray-200 lg:inline-block"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                See what others are saying
              </h2>
              <div className="block w-full h-0.5 max-w-lg mt-6 bg-blue-100 rounded-full"></div>

              <div className="items-center justify-center w-full mt-12 mb-4 lg:flex">
                <div className="flex flex-col items-start justify-start w-full h-auto mb-12 lg:w-1/3 lg:mb-0">
                  <div className="flex items-center justify-center">
                    <div className="w-16 h-16 mr-4 overflow-hidden bg-gray-200 rounded-full">
                      <img
                        src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1700&amp;q=80"
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center">
                      <h4 className="font-bold text-gray-800">John Doe</h4>
                      <p className="text-gray-600">CEO of Something</p>
                    </div>
                  </div>
                  <blockquote className="mt-8 text-lg text-gray-500">
                    "This is a no-brainer if you want to take your business to the next level. If
                    you are looking for the ultimate toolset, this is it!"
                  </blockquote>
                </div>
                <div className="flex flex-col items-start justify-start w-full h-auto px-0 mx-0 mb-12 border-l border-r border-transparent lg:w-1/3 lg:mb-0 lg:px-8 lg:mx-8 lg:border-gray-200">
                  <div className="flex items-center justify-center">
                    <div className="w-16 h-16 mr-4 overflow-hidden bg-gray-200 rounded-full">
                      <img
                        src="https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2547&amp;q=80"
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center">
                      <h4 className="font-bold text-gray-800">Jane Doe</h4>
                      <p className="text-gray-600">CTO of Business</p>
                    </div>
                  </div>
                  <blockquote className="mt-8 text-lg text-gray-500">
                    "Thanks for creating this service. My life is so much easier. Thanks for making
                    such a great product."
                  </blockquote>
                </div>
                <div className="flex flex-col items-start justify-start w-full h-auto lg:w-1/3">
                  <div className="flex items-center justify-center">
                    <div className="w-16 h-16 mr-4 overflow-hidden bg-gray-200 rounded-full">
                      <img
                        src="https://images.unsplash.com/photo-1545167622-3a6ac756afa4?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1256&amp;q=80"
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center">
                      <h4 className="font-bold text-gray-800">John Smith</h4>
                      <p className="text-gray-600">Creator of Stuff</p>
                    </div>
                  </div>
                  <blockquote className="mt-8 text-lg text-gray-500">
                    "Packed with awesome content and exactly what I was looking for. I would highly
                    recommend this to anyone."
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">
              Frequently asked questions
            </h2>
            <div className="mt-12">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                    <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div> */}
        <section className="bg-white">
          <div className="px-8 py-8 mx-auto max-w-7xl">
            <div className="relative px-6 py-10 overflow-hidden border border-blue-100 rounded-2xl lg:p-16 lg:flex lg:flex-col lg:items-center lg:justify-between">
              <div className="absolute top-0 left-0 z-10 hidden h-full p-4 mt-1 ml-3 -mt-4 -ml-4 transform -rotate-90 lg:block">
                <svg
                  className="w-auto h-full fill-current text-blue-50"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 390 390"
                >
                  <defs></defs>
                  <g fillRule="nonzero">
                    <circle cx="10" cy="10" r="9.5"></circle>
                    <circle cx="47" cy="10" r="9.5"></circle>
                    <circle cx="84" cy="10" r="9.5"></circle>
                    <circle cx="121" cy="10" r="9.5"></circle>
                    <circle cx="158" cy="10" r="9.5"></circle>
                    <circle cx="195" cy="10" r="9.5"></circle>
                    <circle cx="232" cy="10" r="9.5"></circle>
                    <circle cx="269" cy="10" r="9.5"></circle>
                    <circle cx="306" cy="10" r="9.5"></circle>
                    <circle cx="343" cy="10" r="9.5"></circle>
                    <circle cx="380" cy="10" r="9.5"></circle>
                    <circle cx="47" cy="47" r="9.5"></circle>
                    <circle cx="84" cy="47" r="9.5"></circle>
                    <circle cx="121" cy="47" r="9.5"></circle>
                    <circle cx="158" cy="47" r="9.5"></circle>
                    <circle cx="195" cy="47" r="9.5"></circle>
                    <circle cx="232" cy="47" r="9.5"></circle>
                    <circle cx="269" cy="47" r="9.5"></circle>
                    <circle cx="306" cy="47" r="9.5"></circle>
                    <circle cx="343" cy="47" r="9.5"></circle>
                    <circle cx="380" cy="47" r="9.5"></circle>
                    <circle cx="84" cy="84" r="9.5"></circle>
                    <circle cx="121" cy="84" r="9.5"></circle>
                    <circle cx="158" cy="84" r="9.5"></circle>
                    <circle cx="195" cy="84" r="9.5"></circle>
                    <circle cx="232" cy="84" r="9.5"></circle>
                    <circle cx="269" cy="84" r="9.5"></circle>
                    <circle cx="306" cy="84" r="9.5"></circle>
                    <circle cx="343" cy="84" r="9.5"></circle>
                    <circle cx="380" cy="84" r="9.5"></circle>
                    <circle cx="121" cy="121" r="9.5"></circle>
                    <circle cx="158" cy="121" r="9.5"></circle>
                    <circle cx="195" cy="121" r="9.5"></circle>
                    <circle cx="232" cy="121" r="9.5"></circle>
                    <circle cx="269" cy="121" r="9.5"></circle>
                    <circle cx="306" cy="121" r="9.5"></circle>
                    <circle cx="343" cy="121" r="9.5"></circle>
                    <circle cx="380" cy="121" r="9.5"></circle>
                    <circle cx="158" cy="158" r="9.5"></circle>
                    <circle cx="195" cy="158" r="9.5"></circle>
                    <circle cx="232" cy="158" r="9.5"></circle>
                    <circle cx="269" cy="158" r="9.5"></circle>
                    <circle cx="306" cy="158" r="9.5"></circle>
                    <circle cx="343" cy="158" r="9.5"></circle>
                    <circle cx="380" cy="158" r="9.5"></circle>
                    <circle cx="195" cy="195" r="9.5"></circle>
                    <circle cx="232" cy="195" r="9.5"></circle>
                    <circle cx="269" cy="195" r="9.5"></circle>
                    <circle cx="306" cy="195" r="9.5"></circle>
                    <circle cx="343" cy="195" r="9.5"></circle>
                    <circle cx="380" cy="195" r="9.5"></circle>
                    <circle cx="232" cy="232" r="9.5"></circle>
                    <circle cx="269" cy="232" r="9.5"></circle>
                    <circle cx="306" cy="232" r="9.5"></circle>
                    <circle cx="343" cy="232" r="9.5"></circle>
                    <circle cx="380" cy="232" r="9.5"></circle>
                    <circle cx="269" cy="269" r="9.5"></circle>
                    <circle cx="306" cy="269" r="9.5"></circle>
                    <circle cx="343" cy="269" r="9.5"></circle>
                    <circle cx="380" cy="269" r="9.5"></circle>
                    <circle cx="306" cy="306" r="9.5"></circle>
                    <circle cx="343" cy="306" r="9.5"></circle>
                    <circle cx="380" cy="306" r="9.5"></circle>
                    <circle cx="343" cy="343" r="9.5"></circle>
                    <circle cx="380" cy="343" r="9.5"></circle>
                    <circle cx="380" cy="380" r="9.5"></circle>
                  </g>
                </svg>
              </div>

              <div className="absolute bottom-0 right-0 z-10 hidden h-full p-4 mt-1 ml-3 -mb-4 -mr-4 transform rotate-90 md:block">
                <svg
                  className="w-auto h-full fill-current text-blue-50"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 390 390"
                >
                  <defs></defs>
                  <g fillRule="nonzero">
                    <circle cx="10" cy="10" r="9.5"></circle>
                    <circle cx="47" cy="10" r="9.5"></circle>
                    <circle cx="84" cy="10" r="9.5"></circle>
                    <circle cx="121" cy="10" r="9.5"></circle>
                    <circle cx="158" cy="10" r="9.5"></circle>
                    <circle cx="195" cy="10" r="9.5"></circle>
                    <circle cx="232" cy="10" r="9.5"></circle>
                    <circle cx="269" cy="10" r="9.5"></circle>
                    <circle cx="306" cy="10" r="9.5"></circle>
                    <circle cx="343" cy="10" r="9.5"></circle>
                    <circle cx="380" cy="10" r="9.5"></circle>
                    <circle cx="47" cy="47" r="9.5"></circle>
                    <circle cx="84" cy="47" r="9.5"></circle>
                    <circle cx="121" cy="47" r="9.5"></circle>
                    <circle cx="158" cy="47" r="9.5"></circle>
                    <circle cx="195" cy="47" r="9.5"></circle>
                    <circle cx="232" cy="47" r="9.5"></circle>
                    <circle cx="269" cy="47" r="9.5"></circle>
                    <circle cx="306" cy="47" r="9.5"></circle>
                    <circle cx="343" cy="47" r="9.5"></circle>
                    <circle cx="380" cy="47" r="9.5"></circle>
                    <circle cx="84" cy="84" r="9.5"></circle>
                    <circle cx="121" cy="84" r="9.5"></circle>
                    <circle cx="158" cy="84" r="9.5"></circle>
                    <circle cx="195" cy="84" r="9.5"></circle>
                    <circle cx="232" cy="84" r="9.5"></circle>
                    <circle cx="269" cy="84" r="9.5"></circle>
                    <circle cx="306" cy="84" r="9.5"></circle>
                    <circle cx="343" cy="84" r="9.5"></circle>
                    <circle cx="380" cy="84" r="9.5"></circle>
                    <circle cx="121" cy="121" r="9.5"></circle>
                    <circle cx="158" cy="121" r="9.5"></circle>
                    <circle cx="195" cy="121" r="9.5"></circle>
                    <circle cx="232" cy="121" r="9.5"></circle>
                    <circle cx="269" cy="121" r="9.5"></circle>
                    <circle cx="306" cy="121" r="9.5"></circle>
                    <circle cx="343" cy="121" r="9.5"></circle>
                    <circle cx="380" cy="121" r="9.5"></circle>
                    <circle cx="158" cy="158" r="9.5"></circle>
                    <circle cx="195" cy="158" r="9.5"></circle>
                    <circle cx="232" cy="158" r="9.5"></circle>
                    <circle cx="269" cy="158" r="9.5"></circle>
                    <circle cx="306" cy="158" r="9.5"></circle>
                    <circle cx="343" cy="158" r="9.5"></circle>
                    <circle cx="380" cy="158" r="9.5"></circle>
                    <circle cx="195" cy="195" r="9.5"></circle>
                    <circle cx="232" cy="195" r="9.5"></circle>
                    <circle cx="269" cy="195" r="9.5"></circle>
                    <circle cx="306" cy="195" r="9.5"></circle>
                    <circle cx="343" cy="195" r="9.5"></circle>
                    <circle cx="380" cy="195" r="9.5"></circle>
                    <circle cx="232" cy="232" r="9.5"></circle>
                    <circle cx="269" cy="232" r="9.5"></circle>
                    <circle cx="306" cy="232" r="9.5"></circle>
                    <circle cx="343" cy="232" r="9.5"></circle>
                    <circle cx="380" cy="232" r="9.5"></circle>
                    <circle cx="269" cy="269" r="9.5"></circle>
                    <circle cx="306" cy="269" r="9.5"></circle>
                    <circle cx="343" cy="269" r="9.5"></circle>
                    <circle cx="380" cy="269" r="9.5"></circle>
                    <circle cx="306" cy="306" r="9.5"></circle>
                    <circle cx="343" cy="306" r="9.5"></circle>
                    <circle cx="380" cy="306" r="9.5"></circle>
                    <circle cx="343" cy="343" r="9.5"></circle>
                    <circle cx="380" cy="343" r="9.5"></circle>
                    <circle cx="380" cy="380" r="9.5"></circle>
                  </g>
                </svg>
              </div>

              <h3 className="relative z-20 mb-4 -mt-1 text-4xl font-bold text-blue-900">
                Getting Started is Easy...
              </h3>
              <p className="relative z-20 mb-6 text-lg text-blue-700">
                Make your to-do list a <em>done</em> list
              </p>
              {/* <div className="relative z-20 flex flex-col items-center w-full space-y-5 md:space-x-5 md:space-y-0 md:flex-row md:w-auto lg:flex-shrink-0 md:px-0">
                <a href="#" className="mr-2 w-48">
                  <img src="/images/app-store.png" />
                </a>
                <a href="#" className="w-48">
                  <img src="/images/google-play.png" />
                </a>
              </div> */}
              <div className="w-full max-w-sm mx-auto">
                <InlineEmail />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-blue-100 text-center mt-12 py-24 px-4">
          <div className="mb-5 text-blue-400 text-lg font-semibold">Mavyn Experts</div>
          <h2 className="text-blue-600 text-6xl font-bold mb-6">Be a Hero's Hero</h2>
          <p className="text-lg leading-8 max-w-2xl mx-auto text-gray-500">
            Are you an expert in your field? Join Mavyn's lineup of experts and help household heros
            across America save the day using your advice.
          </p>
          <div className="mt-12">
            <a
              href={EXPERT_FORM_LINK}
              target="_blank"
              style={{ color: 'white' }}
              className="shadow-xl inline-flex items-center w-full max-w-sm px-16 py-3 border border-transparent text-base font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-center justify-center"
            >
              Become an Expert
            </a>
          </div>
        </section>
        {/* <section className="py-24 bg-blue-700">
          <div className="max-w-6xl px-10 mx-auto xl:max-w-7xl">
            <div className="flex justify-between flex-col md:flex-row">
              <div className="relative">
                <h2 className="text-4xl font-extrabold text-white xl:text-5xl">
                  Are you ready to get your time back?
                </h2>
                <p className="mt-2 text-xl text-blue-300">
                  In your home, on the road, or even in your office - Mavyn has your back
                </p>
              </div>
              <div className="mt-12 md:mt-0 relative z-20 flex flex-col items-center w-full md:w-auto lg:flex-shrink-0 md:px-0">
                <a href="#" className="w-48 mb-4">
                  <img src="/images/app-store.png" />
                </a>
                <a href="#" className="w-48">
                  <img src="/images/google-play.png" />
                </a>
              </div>
            </div>
          </div>
        </section> */}
        <footer className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            {/* <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
              {navigation.main.map((item) => (
                <div key={item.name} className="px-5 py-2">
                  <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                    {item.name}
                  </a>
                </div>
              ))}
            </nav> */}
            <div className="mt-8 flex justify-center space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-center text-base text-gray-400">
              &copy; 2021 Mavyn, Inc. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default HomePage;
